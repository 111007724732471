import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/Seo';
import {Container, Row, Col} from 'styled-bootstrap-grid';
import colors from '../styles/colors';
import fonts from '../styles/fonts';
import styled from 'styled-components';


const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-grow: 1;
`;

const PageTitle = styled.h1`
  color: ${colors.grey};
  ${fonts.CormorantGaramondLight};
  font-size: 40px;
  line-height: 48px;
  margin: auto;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  @media(min-width: 992px) {
      font-size: 50px;
      line-height: 58px;
      margin-top: 150px;
      padding-left: 30px;
      padding-right: 30px;
      max-width: 80%;
  }
`;

const NotFoundPage = () => {
    return (
      <Layout>
        <SEO title='Page not found.'
             description='The page you are looking for has been removed or relocated.'/>
             <StyledContainer>
               <Row>
                 <Col>
                  <PageTitle>The page you are looking for has been removed or relocated.</PageTitle>
                 </Col>
               </Row>
             </StyledContainer>
      </Layout>
    );
}

export default NotFoundPage;
